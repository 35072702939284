/* @import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200); */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800);

:root {
  --main-color: #8762B0;
  --secondary-color:#C5405E;
  --ash-grey: #d8d8d8;
  --alli-red: #d84465;
  --alli-green: #2f6165;
  --alli-purple: #a47ec6;
  --alli-black: #4c4e56;

  --px12: 0.75rem;
  --px13: 0.813rem;
  --px14: 0.875rem;
  --px15: 0.9375rem;
  --px17: 1.0625rem;
  --px18: 1.125rem;
  --px19: 1.1875rem;
  --px20: 1.25rem;

  /* Used in app */

  --px16: 1rem;
  --px24: 1.5rem;
  --px28: 1.75rem;
  --px32: 2rem;
  --px36: 2.25rem;
  --px42: 2.625rem;
  --textColor: #686868;
  --headerColor:#000000;
  --borderColor:#CECECE;
}

@font-face {
  font-family: 'Amasis';
  src: url(./assets/fonts/AmasisMTW1G.otf);
}
@font-face {
  font-family: 'Amasis-Bold';
  src: url(./assets/fonts/AmasisMTW1G-Bold.otf);
}

* {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  color: black;


}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}


.material-icons {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-symbols-outlined {
  font-family: 'Material Icons Outlined' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}






/*************************  Header  *************************/

.header{
  display: flex;
  top: 0;
  left: 0;
  padding:10px 15px;
  justify-content: center;
  background: white;
  position: sticky;
}

.header > .header_homepage {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
}

.header > img {
  height: var(--px36);
  object-fit: contain;
}

.header > h4{
  font-size: var(--px24);
  font-weight: 600;
}

.header > .header-left{
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.header > .header-left > img{
  object-fit: contain;
  width: var(--px36);
  border-radius: 50%;
}

.header > .header-left > h4{
  font-size: var(--px16);
  font-weight: 600;
  margin-left: 10px;
}

.header > .header-right{
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.profile-logout{
  display: flex;
  align-items: center;
  gap: 10px;
}

.homepage_menu {
  display: flex;
  align-items: center;
  gap: 10px;
}

/*************************  Login Page  *************************/

.login-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  min-height: 100vh;
  padding-bottom: 40px;
  max-width: 800px;
}

.login-container > .login-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 6dvh;
}

.login-container > .login-title > h1{
  font-size: var(--px42);
  color: var(--headerColor);
  font-weight: bold;
  line-height: 36px;
}

.login-container > .login-title > p{
  font-size: var(--px16);
  color: var(--textColor);
  margin-top: 10px;
}

.login-container > img{
  object-fit: contain;
  width: 100%;
}

.login-background{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.login-background > svg{
  height: 100vh;
  width: 100%;
}

.login-head-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.login-head-content > h3 {
  font-weight: 500;
  font-size: var(--px32);
  color: var(--ash-grey);
  font-family: 'Amasis-Bold' !important;
}

.login-head-content > h3 > span {
  color: #3c5d64;
  font-family: 'Amasis-Bold' !important;
  font-weight: 600;
}

.login-middle-content{
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.login-middle-content > img {
  object-fit: contain;
  width:100%;
  height: 100%;
  padding-bottom: 55px;
}

.login-middle-content > h2 {
  font-size: 1.5rem;
  font-family: 'Amasis' !important;
  color: #acacac;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-middle-content > h3 {
  font-size: 1rem;
  font-family: 'Amasis' !important;
  color: #818181;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  background-color: #000;
  opacity: .8;
  border-radius: 1rem;
  padding: .5rem;
}

.login-form-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;

}

.login-form-container > p {
  font-size: .7rem;
  text-align: center;
}

.login-form-container a {
  color: #007bff;
  text-decoration: none;
}

.login-form-container > button{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  background: none;
  padding: 10px 0px;
  border: solid 1px var(--borderColor);
  width: 100%;
  gap: 10px;
}

.login-form-container > button > img {
  object-fit: contain;
  height: var(--px32);
}

.login-form-container > button > span {
  color: var(--headerColor);
  font-weight: 600;
  font-size: var(--px16);
}

/*************************  Terms of Service  *************************/

.terms_of_service {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
  justify-content: center;
  align-items: center;
}

.terms_of_service_container {
  max-width: 800px;
  margin: .5rem;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.terms_of_service_signoff {
  display: flex;
  text-align: center;
}

.terms_of_service_container h2 {
  color: #333;
  padding-bottom: 1rem;
}

.terms_of_service_container h3 {
  color: var(--alli-black);
  margin: .5rem 0;
}

.terms_of_service_container h4 {
  color: var(--alli-black);
}

.terms_of_service_container p {
  line-height: 1.6;
  color: #555;
  margin: .5rem .7rem;
}

.terms_of_service_container span {
  font-weight: 600;
}

.terms_of_service_container a {
  color: #007bff;
  text-decoration: none;
}

.terms_of_service_container a:hover {
  text-decoration: underline;
}

/*************************  Privacy Policy  *************************/
.privacy_policy {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

.privacy_policy_container {
  max-width: 800px;
  margin: .5rem;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.privacy_policy_signoff {
  display: flex;
  text-align: center;
}

.privacy_policy_container h2 {
  color: #333;
  padding-bottom: 1rem;
}

.privacy_policy_container h3 {
  color: var(--alli-black);
  margin: .5rem 0;
}

.privacy_policy_container h4 {
  color: var(--alli-black);
}

.privacy_policy_container p {
  line-height: 1.6;
  color: #555;
  margin: .5rem .7rem;
}

.privacy_policy_container span {
  font-weight: 600;
}

.privacy_policy_container a {
  color: #007bff;
  text-decoration: none;
}

.privacy_policy_container a:hover {
  text-decoration: underline;
}

/*************************  Social Page  *************************/

.postSubContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: flex-start;
  padding: 5px;
}

.socialHeader {
  margin: .5rem 1rem;
  font-size: 1.5rem;
  text-align: center;
}

.socialHeader > a {
  color: var(--alli-purple);
  text-decoration: underline;
}

.postText {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.postUser {
  font-size: var(--px16);
}

.postText > p {
  font-size: var(--px12);
  color: var(--alli-grey);
}

.postAlli {
  height: var(--px42);
  border-radius: 25px;
  background-color: var(--alli-purple);
}



/*************************  Profile Page  *************************/

.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
}

.profile-image {
  display: flex;
  justify-content: center;
}

.profile-avatar {
  display: flex;
  height: 10rem;
  border-radius: 50%;
}

.profile-text {
  text-align: center;
}

.signout {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.signout > button {
  padding: 10px 20px;
  background-color: var(--alli-red);
  color: white;
  font-weight: 700;
  border-radius: 15px;

}

/*************************  Discover Page  *************************/

.discover-container {
  display: flex;
  flex-direction: column;
  margin: 25px 0;
  white-space: wrap;
}

.discover-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.discover-title p {
  color: var(--alli-green);
  font-size: var(--px12);
  font-weight: 700;
}

.discover-container > h2 {
 font-size: var(--px28);
 font-weight: 600;
 margin: 0px 15px;
}

.discover-overflow {
  display: flex;
  gap: 10px;
  padding:5px;
  padding-left: 15px;
  overflow: auto;
  @media (min-width: 768px) {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
}

.discover-overflow::-webkit-scrollbar { 
  @media (min-width: 768px) {
    display: none;  /* Safari and Chrome */ 
  }
}

.discover-overflow-item {
  display: flex;
  flex-direction: column;
}

.discover-overflow-item > img {
  object-fit: contain;
  width: 7rem;
  height: 7rem;
  border-radius: 9px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);  
}

.discover-overflow-item > h5 {
  font-size: var(--px14);
  margin-top: 5px;
  font-weight: 600;
  width: 7rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; 
  color: var(--headerColor);
}

.discover-overflow-item > p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; 
  width:7rem;
  font-size: var(--px14);
  color: var(--textColor);
}

.discover-genre-container {
  margin:20px 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.discover-genre-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 9px;
  padding: 10px 10px;
  box-sizing: border-box;
  border: solid 1px var(--borderColor);
  gap: 10px;
}

.discover-genre-item > h4 {
  font-size: var(--px14);
  font-weight: 600;
}

.discover-genre-item > span {
    display: block;
    width: 5px;
    height: 100%;
    border-radius:9px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/*************************  Podcastlist  *************************/

.podcastlist-grid-container {
  margin:20px 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  justify-items: center;
}

.podcastlist-grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8rem;
 }

.podcastlist-grid-item img {
  object-fit: cover;
  width: 8rem;
  height: 8rem;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);  
 }

.podcastlist-header-text {
display: flex;
flex-direction: column;
padding: 10px 0px;
width: 100%;
}

.podcastlist-header-text > h5 {
font-size: var(--px13);
margin-top: 5px;
font-weight: 600;
width: 30vw;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden; 
color: var(--headerColor);
}

.podcastlist-header-text > p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 30vw;
  font-size: var(--px12);
  color: var(--textColor);
  font-weight: 600;
}

/*************************  Podcast Page  *************************/

.podcast-container{
  display: flex;
  flex-direction: column;
  margin: 0px 15px;
  margin-bottom: 90px;
}

.podcast-header{
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.podcast-header > img{
  object-fit: contain;
  width: 8rem;
  height: 8rem;
  border-radius: 9px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);  
}

.podcast-header-text{
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  width: 100%;
}

.podcast-header-text > h1{
  font-size: var(--px20);
}

.podcast-header-text > p{
  font-weight: 600;
  color: var(--textColor);
  font-size: var(--px14);
}

.podcast-description{
  margin-top: 15px;
  margin-bottom: 20px;
}

.podcast-modal .m-60c222c7{
  padding: 0px;
}

.podcast-modal .m-615af6c9 {
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}

.podcast-modal .m-54c44539 {
  flex:1;
  margin-top: 10rem;
  min-height: -webkit-fill-available;
  padding-bottom: 8rem;
}

.podcast-preview{
  color: var(--textColor);
  font-size: var(--px14);
  line-height: 21px;
  margin-top: 5px;
}

.podcast-preview *{
  color: var(--textColor);
  display: inline;
}

  
.podcast-preview > span{
  color: var(--secondary-color);
  font-size: var(--px14);
}

.podcast-preview > img {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
}

.podcast-episode {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.podcast-episode-header {
  display: flex;
}

.podcast-episode-header-text {
  display: flex;
  flex-direction: column;
}

.podcast-episode-header > img {
  width: 4rem;
  height: 4rem;
  margin-right: .5rem;
}

.podcast-episode-header-text > h3 {
  font-size: var(--px18);
  font-weight: 600;
  line-height: 24px;
}

.podcast-episode-header-text > p {
  font-size: var(--px14);
  line-height: 24px;
}

.podcast-episode-buttons{
  display: flex;
  gap: 20px;
}

.podcast-episode-buttons > button {
  display: flex;
  align-items: center;
  border: none;
  width: fit-content;
  justify-content: center;
  gap: 5px;
  font-weight: 600;
  padding: 5px 5px;
  font-size: var(--px14);
  border-radius: 9px;
  margin-top: 12px;
}

.podcast-episode-buttons > img {
  display: flex;
  align-items: center;
  border: none;
  width: fit-content;
  height: 2rem;
  justify-content: center;
  background: var(--main-color);
  gap: 5px;
  font-weight: 600;
  padding: 0 5px;
  font-size: var(--px14);
  border-radius: 9px;
  margin-top: 12px;
}

.podcast-episode-buttons > button > i {
  font-size: var(--px20);
}

.podcast-episode-buttons > button:first-child {
  background: var(--main-color);
  color: white;
}

.podcast-episode-buttons > button:last-child {
  background: none;
}

.podcast-episode-buttons > button:first-child > i{
  background: var(--main-color);
  color: white;
}

.subscribe-btn > button {
  display: flex;
  padding-top: var(--px14);
  background: none;
  border: none;
  height: fit-content;
  align-items: center;
  font-size: var(--px13);
  font-weight: 700;
}

.subscribe > button {
  color: var(--alli-green);
  display: flex;
  padding-top: var(--px14);
  background: none;
  border: none;
  height: fit-content;
  align-items: center;
  font-size: var(--px13);
  font-weight: 700;
}

.subscribe > button > i {
  color: var(--alli-green);
}

.unsubscribe > button {
  display: flex;
  padding-top: var(--px14);
  background: none;
  border: none;
  height: fit-content;
  align-items: center;
  color: var(--alli-red);
  font-size: var(--px13);
  font-weight: 700;
}

.unsubscribe > button > i {
  color: var(--alli-red);
}

/*************************  Player  *************************/

.player-container{
  display: flex;
  flex-direction: column;
  margin: 15px 15px;
  align-items: center;
  flex-grow: 1;
}

.player-container > img{
  object-fit: contain;
  width: 65vw;
  border-radius: 9px;
}

.player-container > h1{
  color: var(--headerColor);
  font-size: var(--px24);
  margin-top: 20px;
}

.player-bar{
  display: flex;
  width: 100%;
  height: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.player-bar > div{
  width: 0px;
  height: 7.5px;
  border-radius: 9px;
  position: absolute;
}

.player-bar > .player-default-bar{
  width: 100%;
  background: var(--secondary-color);
  opacity: .2;
}

.player-bar > .player-moving-bar{
  background: var(--main-color);
  opacity: .9;
  filter: saturate(2);
  pointer-events: none;
  touch-action: none;
  left: 0;
}

.player-bar > .player-markerbox-bar{
  width: calc(100% - 3.75 * 2px);
  pointer-events: none;
  touch-action: none;
  z-index: 20;
  border-radius: 0px;
}

.player-bar > span{
  display: block;
  pointer-events: none;
  width: 7.5px;
  padding: 7px;
  left: 0px;
  margin-left: -3.75px;
  background: var(--main-color);
  height: 7.5px;
  border-radius: 9px;
  position: absolute;
  touch-action: none;
  filter: brightness(.9);
}

.player-timers {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: var(--px14);
}

.player-timers > p {
  color: var(--textColor);

}

.player-controls {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 10px;
} 

.player-controls > button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
} 

.player-controls > select {
  border: none;
  outline: none;
}

.player-controls-loading {
  margin-bottom: 5rem;
}

.playpause {
  background: var(--secondary-color) !important;
  border-radius: 50%;
  display: flex;
}

.playpause > i{
  font-size: 3.375rem;
  color: white;
  align-self: center;
}

.rewind > i {
  font-size: 2rem;
}

.forward > i {
  font-size: 2rem;
}

.rewind_5s > i {
  font-size: 2rem;
}

.rewind_30s > i {
  font-size: 2rem;
}

.forward_5s > i {
  font-size: 2rem;
}

.forward_30s > i {
  font-size: 2rem;
}

.player-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.playback-speed {
  display: flex;
  align-items: center;
  justify-self: left;
}

.playback-speed > select {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: var(--px12);
}

.sleep-timer {
  display: flex;
  align-items: center;
  justify-self: left;
}

.sleep-timer > select {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: var(--px12);
}

/*************************  Compact Player  *************************/

.compact-player {
  position: fixed;
  bottom: 90px;
  left: 0;
  width: 100%;
  background-color: white;
  color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
}

.compact-player > img {
  width: 5rem;
  padding: .5rem;
}

.compact-player-info {
  margin-left: 10px;
  margin-right: 1rem;
  width: 100%;
}

.compact-player-info > span {
  font-size: var(--px14);
  overflow: hidden;
  width: 50vw;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/*************************  Library  *************************/

.library-container{
  display: flex;
  flex-direction: column;
  margin: 20px 15px 90px;
  gap: 10px;
}

.library-container h2 {
  padding-top: 1rem;
  padding-bottom: .5rem;
  align-self: flex-start;
}

.library-row{
  display: flex;
}

.library-action{
  display: flex;
  width: 100%;
}

.library-img-container{
  display: grid;
  grid-template-columns:  repeat( auto-fit, minmax(3.5rem, 1fr) );
  width: 7rem;
  height: 7rem;
  border-radius: 9px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1); 
  overflow: hidden;
  flex-shrink: 0;
}

.library-img-container > img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.library-row > button{
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  height: fit-content;
  align-items: center;
}

.systemPlaylistHeader {
  display: flex;
  flex-direction: row;
}

.systemPlaylistTitle {
  color: var(--alli-purple);
}

.systemPlaylistLoader {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.updateSystemPlaylist {
  padding-top: 10px;
  padding: 5px;
  margin-top: 20px;
  margin-left: 15px;
  color: var(--alli-black);
  background-color: var(--alli-purple);
  font-size: var(--px14);
  font-weight: 700;
  border-radius: 24px;
  width: 8rem;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, 
  rgba(0, 0, 0, .14) 0 6px 10px 0,
   rgba(0, 0, 0, .12) 0 1px 18px 0;
}

.latestSystemPlaylist {
  padding-top: 10px;
  padding: 5px;
  margin-top: 20px;
  margin-left: 15px;
  color: var(--alli-black);
  background-color: var(--alli-purple);
  font-size: var(--px14);
  font-weight: 700;
  text-align: center;
  border-radius: 24px;
  width: 5rem;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, 
  rgba(0, 0, 0, .14) 0 6px 10px 0,
  rgba(0, 0, 0, .12) 0 1px 18px 0;
}

.loadingSystemPlaylist {
  padding-top: 10px;
  padding: 5px;
  margin-top: 20px;
  margin-left: 15px;
  align-content: center;
  color: var(--alli-black);
  background-color: var(--alli-purple);
  border-radius: 24px;
  width: 3rem;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, 
  rgba(0, 0, 0, .14) 0 6px 10px 0,
  rgba(0, 0, 0, .12) 0 1px 18px 0;
}

.systemPlaylistContainer{
  display: flex;
  flex-direction: column;
  margin: 20px 15px;
  gap: 10px;
}

.systemPlaylist-empty {
  text-align: center;
  color: var(--alli-purple);
  font-size: var(--px20);
  font-weight: 500;
}

.systemPlaylistTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.systemPlaylistTextContainer > h1 {
  font-size: var(--px18);
  color: var(--alli-purple);
  font-weight: 700;
  width: 50vw;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.systemPlaylistTextContainer > p {
  font-size: var(--px14);
  color: var(--textColor);
  color: var(--alli-purple);
  overflow: hidden;
  width: 50vw;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.userPlaylistTitle {
  color: var(--alli-green);
}

.userPlaylistContainer{
  display: flex;
  flex-direction: column;
  margin: 20px 15px;
  gap: 10px;
}

.userPlaylist-empty {
  text-align: center;
  color: var(--alli-green);
  font-size: var(--px20);
  font-weight: 500;
}

.userPlaylistTextContainer {
  display: flex;
  padding-top: var(--px14);
  height: fit-content;
  font-size: var(--px13);
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden; 
}

.userPlaylistTextContainer > h1 {
  font-size: var(--px18);
  color: var(--alli-green);
  font-weight: 700;
  width: 50vw;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.userPlaylistTextContainer > p {
  font-size: var(--px14);
  color: var(--alli-green);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subscriptionTitle {
  color: var(--alli-red);
}

.subscriptionContainer {
  display: flex;
  margin: 20px 15px;
  gap: 10px;
}

.subscription-empty {
  text-align: center;
  color: var(--alli-red);
  font-size: var(--px20);
  font-weight: 500;
}

.subscriptionTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden; 
}

.subscriptionTextContainer > h1 {
  font-size: var(--px18);
  color: var(--alli-red);
  font-weight: 600;
  width: 50vw;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.subscriptionTextContainer > p {
  font-size: var(--px14);
  color: var(--alli-red);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/*************************  Playlist  *************************/

.playlist-modal-action-button{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: #0000000c;
  border-radius: 9px;
  width: 100%;
  padding: 10px 0px;
  border: none;
  font-weight: 600;
  margin-top: 20px;
}

.playlist-modal-not-found{
  color: var(--textColor);
  text-align: center;
  margin: 0px auto;
  margin-top: 20px;
}

.playlist-options {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0px;
}

.playlist-options button {
  background: transparent;
  border: none;
}

/*************************  Search  *************************/

.search_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 15px;
  gap: 20px;
}

.search_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.search_box > input {
  outline: transparent;
  border: solid 1px black;
  border-radius: 9px;
  padding: .3rem;
}

.search_box > button {
  padding: .3rem .4rem;
}

.load_more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.5rem;
}

.load_more > button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 12px;
  background: var(--alli-green);
  padding: 10px 0px;
  margin-top: 3rem;
  margin-bottom: 10rem;
  border: solid 2px var(--borderColor);
  width: 85%;

  @media (min-width:768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 12px;
    background: var(--alli-green);
    padding: 10px 0px;
    border: solid 2px var(--borderColor);
    width:30%;
  }
}

/*************************  Navbar  *************************/

.bottom-nav-container {position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: white;
  height: 90px;
  padding: 0px 15px;
  z-index: 150;
  bottom: 0px;
  border-top: solid 1px var(--borderColor);
  padding-bottom:calc(env(safe-area-inset-bottom) + 25px);
  padding-top: 5px;
}

.bottom-nav {width: 100%;display: flex;background-color:transparent;height: 58px;border-radius: 0px;justify-content: space-around;padding: 0px 0px;}

.bottom-nav-item {display: flex; flex-direction: column; justify-content: center; align-items: center;gap: 3px;}

.bottom-nav-item > p {font-size: var(--px12);font-weight: 500;}

.bottom-nav > a {display: flex; align-items: center;padding: 12px;}

.inactive-nav {filter: opacity(0.4)grayscale(100%);}

.active-nav {filter: opacity(1);}

.active-nav > div > i {color: var(--main-color);}

.bottom-nav-item > i {font-size: var(--px28);transition: 0.2s ease;}

.nav-bar-ghost-spacer {height: 120px;width: 100%;}


.page-fill-height{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


/*************************  Contact Page  *************************/

.contact_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.contact_label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
}
  
.contact_input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.contact_textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 3px;
}
/* Submit button */
.contact_submit_button {
background-color: #007bff;
color: #fff;
padding: 10px 20px;
border: none;
border-radius: 3px;
cursor: pointer;
transition: background-color 0.3s;
}
.contact_submit_button:hover {
background-color: #0056b3;
}
/* Success and Error Messages */
.contact_message {
font-weight: bold;
margin-top: 10px;
}
.contact_success {
color: #009900;
}
.contact_error {
color: #ff0000;
}

/*************************  About  *************************/

.about_body {
  font-family: 'Arial', sans-serif;
  background-color: #360202;
  margin: 0;
  padding: 0;
}

.about_container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.about_container > img {
  width: 100%;
  height: 100%;
}

.about_container > p {
  color: #666;
  line-height: 1.5;
}

.about_container > p > span {
  color: var(--alli-grey);
  font-weight: 600;
  text-decoration: underline;
}

.about_container p + p {
  margin-top: 15px;
}


/*************************  Timeline  *************************/

.roadmapContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 20px;
  background-image: linear-gradient(to bottom,#F1F3FE,#f13367,#3C5D64,#7030A0);
}

.roadmapHeader {
  margin: .5rem 1rem;
  font-size: 1.5rem;
}

.timelineContainer {
  width: 300px;
  height: 100%;
  margin: auto;
  background-color: white;
  border-width: 10px;
  border-color: #F13367;
  border-radius: 10px;
  padding: 10px;
}

.milestoneContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  text-align:  center;
  justify-content: center;
  overflow: hidden;
  font-size: .9rem;
}

.AlliRelationships {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  object-fit: contain;
  align-items: center;
  justify-content: center;
}

.AlliRelationships img {
  width: 50px;
  height: auto;
}

.flavorText {
  color: #7030a0;
  font-weight: 700;
}

.completeFlavorText {
  color: gray;
  font-weight: 700;
}

.milestoneLink {
  color: blue;
}

.completeMilestoneLink {
  color: gray;
}

.flavorLogo {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.flavorLogo img {
  width: 50px;
  height: auto;
}

.milestoneSubContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
}

.milestone {
  transform: rotate(-90deg);
  font-size: 1rem;
  font-weight: 700;
  color: #f13367;
  padding-top: 2rem;
}

.completeMilestone {
  transform: rotate(-90deg);
  font-size: 1rem;
  font-weight: 700;
  color: gray;
  padding-top: 2rem;
}

.milestoneNumber {
  font-size: 6rem;
}

.completeMilestoneNumber {
  font-size: 6rem;
  color: gray;
}

.milestoneText {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin: 0 .5rem;
}

.completeMilestoneText {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin: 0 .5rem;
}

.milestoneText p {
  display: flex;
  height: 100%;
  width: 100%;
}

.completeMilestoneText p {
  display: flex;
  height: 100%;
  width: 100%;
  color: gray;
}